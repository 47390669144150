<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14 nav-selected">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-16">
          <span class="fm-16">파트너 가입자 정보</span>
          <p class="fr-12 mt-8">
            현재까지 입력하신 가입자 정보를 확인 후에 주소를 입력해 주세요.
          </p>
        </div>
        <div class="mb-16 d-flex justify-content-center column">
          <div class="explanation-box fr-12 f-g60">
            입력하신 개인정보는 구매자에게 노출되지 않으며, 별도의 파트너
            프로필이 노출 됩니다. (가입 후 프로필 설정 가능)
          </div>
        </div>
        <ul class="my_information">
          <li class="li-wrap">
            <span class="address-title">파트너명</span>
            <span class="address-value">{{ name }}</span>
          </li>
          <li class="li-wrap">
            <span class="address-title">생년월일</span>
            <span class="address-value">{{ formatBirth(birth) }}</span>
          </li>
          <li class="li-wrap">
            <span class="address-title">성별</span>
            <span class="address-value">{{
              gender === "M" ? "남성" : "여성"
            }}</span>
          </li>
          <li class="li-wrap">
            <span class="address-title">연락처</span>
            <span class="address-value">{{ formatPhone(phone) }}</span>
          </li>
          <li class="li-wrap">
            <span class="address-title">이메일</span>
            <span class="address-value">{{ email }}</span>
          </li>
          <li class="li-wrap" style="display: flex">
            <span class="address-title">주소</span>
            <div style="padding-left: 16px">
              <div class="flex-align-center">
                <input
                  type="text"
                  class="mr-8"
                  style="width: 320px"
                  placeholder="주소찾기를 눌러 주소를 입력해주세요."
                  :disabled="true"
                  v-model="roadAddress"
                />
                <button-common
                  :size="SIZE_T"
                  :clr="CLR_G"
                  @click="openAddress()"
                  >주소변경</button-common
                >
              </div>
              <input
                type="text"
                class="mt-8"
                style="width: 320px"
                placeholder="상세주소를 입력해주세요."
                v-model="detailAddress"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="roadAddress.length === 0 || detailAddress.length === 0"
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regAddress()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Address",
  components: {},
  data() {
    return {
      name: "",
      birth: "",
      gender: "",
      phone: "",
      email: "",
      roadAddress: "",
      detailAddress: "",
    };
  },
  async mounted() {
    await this.getRegInfo();
  },
  methods: {
    async getRegInfo() {
      const rs = await this.$axios({
        url: "/partner/reg/info",
        params: {},
      });
      if (rs.result === "SUC") {
        this.setData(rs);
      }
    },
    setData(data) {
      this.name = data.name ?? "";
      this.birth = data.birth ?? "";
      this.gender = data.gender ?? "";
      this.phone = data.phone ?? "";
      this.email = data.email ?? "";
    },
    formatBirth(birth) {
      if (birth) {
        let year = birth.substring(0, 2);
        let month = birth.substring(2, 4);
        let date = birth.substring(4);
        if (parseInt(year) < 20) {
          year = "20" + year;
        } else {
          year = "19" + year;
        }
        return year + "." + month + "." + date;
      }
    },
    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
    async openAddress() {
      await this.$openAddress(
        {
          cbPrmy: this.cbSelectAddress,
        },
        this
      );
    },
    cbSelectAddress(address) {
      this.roadAddress = address;
    },
    async regAddress() {
      const rs = await this.$axios({
        url: "/partner/reg/addr",
        params: { addr: this.roadAddress, addr2: this.detailAddress },
      });
      if (rs.result === "SUC") {
        this.goNextPage();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/category`,
        meta: { id: "0-1-6" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.li-wrap {
  padding: 16px 32px;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
.address-title {
  width: 200px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d4d;
}
.address-value {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}
</style>
